import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "./pages/home/home.component";
import {AdminAuthGuardService} from "./services/admin-auth-guard.service";
import {NavComponent} from "./components/nav/nav.component";
import {LoginComponent} from "./pages/login/login.component";
import { UserSettingsComponent } from './pages/settings/user-settings.component';
import {UserSettingsIconsComponent} from "./pages/settings/user-settings-icons.component";
export const routes: Routes = [
  {path: '', component:  HomeComponent, canActivate: [AdminAuthGuardService]  },
  {path: 'login', component:  LoginComponent, canActivate: []  },
  {path: '', loadChildren: () => import('./modules/routing/driving-schools.module').then(m => m.DrivingSchoolsRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/education.module').then(m => m.EducationRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/chat.module').then(m => m.ChatRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/sign-up.module').then(m => m.SignUpRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/accounting-orders.module').then(m => m.AccountingOrdersRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/inventory.module').then(m => m.InventoryRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/documents.module').then(m => m.DocumentsRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/presentations.module').then(m => m.PresentationsRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/questions-topics.module').then(m => m.QuestionsTopicsRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/automization.module').then(m => m.AutomizationRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/other.module').then(m => m.OtherRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/billing.module').then(m => m.BillingRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/fahro.module').then(m => m.FahroRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/users.module').then(m => m.UsersRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/finance.module').then(m => m.FinanceRoutingModule), canActivate: [AdminAuthGuardService]  },
  {path: '', loadChildren: () => import('./modules/routing/hidden.module').then(m => m.HiddenRoutingModule), canActivate: [AdminAuthGuardService]  },
  { path: 'user/settings', redirectTo: 'user/settings/', pathMatch: "full"},
  { path: 'user/settings/:route', component: UserSettingsComponent, canActivate: [AdminAuthGuardService] },
  { path: 'user/icons', component: UserSettingsIconsComponent, canActivate: [AdminAuthGuardService] },
  {path: '**', component:  HomeComponent, canActivate: [AdminAuthGuardService]  },
]
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}