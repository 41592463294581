import {NgModule, isDevMode, Type, ModuleWithProviders, Provider, EnvironmentProviders} from '@angular/core';
import {SharedModule} from './shared.module';
import {AppComponent} from './app.component';
import {UtilsService} from './services/utils.service';
import {MatDialog} from "@angular/material/dialog";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MTX_DATETIME_FORMATS} from '@ng-matero/extensions/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {PrefetchService} from './services/prefetch.service';
import {ApiService} from "./services/api.service";
import {ApiServiceAdminBase} from "./services/api-admin-base.service";
import {ApiServiceBase} from "./services/api-base.service";
import {SnackbarService} from "./services/snackbar.service";
import {RouteEventsService} from "./services/route-events.service";
import {SettingsService} from "./services/settings.service";
import {AdminAuthGuardService} from "./services/admin-auth-guard.service";
import {DateService} from "./services/date.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {AppRoutingModule} from "./app-routing.module";
import {FilterService} from "./services/filter.service";

import {HttpClientModule} from '@angular/common/http';
import {CustomMatPaginatorIntl} from "./components/table/CustomMatPaginatorIntl";
import {GlobalKeyService} from "./services/global-key.service";

import {MatPaginatorIntl} from '@angular/material/paginator';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from "@angular/material/tooltip";
import {CustomTitleService} from "./services/custom-title-service";
import { ServiceWorkerModule } from '@angular/service-worker';
import {environment} from "../environments/environment";

export interface AdditionalModule {
  imports: Array<Type<any> | ModuleWithProviders<{}> | any[]>;
  exports: Array<Type<any> | any[]>;
  providers: Array<Provider | EnvironmentProviders>;
  declarations: Array<Type<any> | any[]>;
}

@NgModule({
    declarations: [],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        SharedModule,
        HttpClientModule,
      ServiceWorkerModule.register(`./sw.js?baseUrl=${encodeURIComponent(environment.baseUrl)}`, {
        enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        ApiService,
        GlobalKeyService,
        ApiServiceAdminBase,
        ApiServiceBase,
        UtilsService,
        SnackbarService,
        SettingsService,
        RouteEventsService,
        PrefetchService,
        AdminAuthGuardService,
        DateService,
        MatSnackBar,
        CustomTitleService,
        MatDialog,
        MatBottomSheet,
        FilterService,

        {
            provide: MTX_DATETIME_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.YYYY',
                    monthInput: 'MMMM',
                    yearInput: 'YYYY',
                    timeInput: 'HH:mm',
                    datetimeInput: 'DD.MM.YYYY HH:mm',
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthInput: 'MMMM',
                    yearInput: 'YYYY',
                    timeInput: 'HH:mm',
                    datetimeInput: 'DD.MM.YYYY HH:mm',
                    monthYearLabel: 'YYYY MMMM',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                    popupHeaderDateLabel: 'MMM DD, ddd',
                },
            },
        },
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
                showDelay: 100,
                hideDelay: 100,
                touchendHideDelay: 1000,
            },
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL'],
                },
                display: {
                    dateInput: 'L',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        }, {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
